@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  display: flex;
  flex-direction: column;
  padding: fn.spacing(6) 0;
}

.eyebrow,
.eyebrow-only {
  font-size: 14px;
  letter-spacing: 3.5px;
  line-height: 16.8px;
}

.eyebrow {
  padding-bottom: fn.spacing(1);
}

.eyebrow-only {
  padding-bottom: fn.spacing(4);
}

.disclaimer {
  color: var.$color-neutral-gray-eighty;
  padding-top: fn.spacing(4);
  text-align: center;
  max-width: 803px;
  width: 100%;
}

.testimonial-image-container {
  position: relative;
}

.testimonial-image {
  border-radius: var.$achieve-default-border-radius;
  overflow: hidden;
}

.stat-grid-container {
  width: 100%;
  padding-bottom: fn.spacing(4);

  div:first-child {
    padding-top: 0;
  }
}

@keyframes fade-up {
  from {
    bottom: -10px;
    opacity: 0;
  }

  to {
    bottom: -20px;
    opacity: 1;
  }
}

.testimonial-image-title {
  background: linear-gradient(90deg, #dff7fd 0%, #e4dafb 68.43%, #ddf3ff 95.43%);
  border-radius: 10px;
  bottom: -20px;
  box-shadow: 0 1.6553px 3.3106px 0 rgb(16 24 40 / 5%);
  color: var.$color-primary-trust-dark-blue;
  left: 50%;
  padding: fn.spacing(1) fn.spacing(2);
  position: absolute;
  transform: translateX(-50%);
  white-space: nowrap;
  animation-name: fade-up;
  animation-duration: 0.5s;
}

.testimonial-copy {
  max-width: 803px;
  padding-bottom: fn.spacing(1);

  strong {
    font-weight: 500;
  }
}

/* Medium (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .eyebrow,
  .eyebrow-only {
    font-size: 14px;
  }

  .eyebrow {
    padding-bottom: fn.spacing(2);
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(8) 0;
  }
}
