@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.divider {
  border-top: 1px solid var.$color-neutral-grey-4;
  margin: fn.spacing(4) 0;
}

.stat-grid-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: fn.spacing(4);
}

.stat-grid-sub-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.stat-grid-element {
  display: 'flex';
  justify-content: space-around;
  text-align: center;
}

.blue-text {
  color: var.$color-primary-achieve-blue;
}

.subtitle {
  flex-direction: column;
  max-width: fn.spacing(101);
  margin: auto;
  padding-top: fn.spacing(6);
}
